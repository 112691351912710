var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.assetDimension && _vm.selectedTimeframeParam && _vm.assetsSelected ? _c('AssetDimensionPivotTableWidget', {
    attrs: {
      "selectedTimeframeParam": _vm.selectedTimeframeParam,
      "assets": _vm.assetsSelected,
      "selectedUIUnitSystem": _vm.selectedUIUnitSystem,
      "isMobile": _vm.isMobile,
      "elementSize": _vm.elementSize,
      "assetDimension": _vm.assetDimension,
      "widget": _vm.widget,
      "isPreview": _vm.isPreview,
      "allAssets": _vm.assetStore.allAssets
    }
  }) : _c('TalpaLoaderWrapper');
}
var staticRenderFns = []

export { render, staticRenderFns }