<template>
  <PivotTableWrapper :hideToolBox="!showToolBox">
    <header>
      <WidgetHeaderMolecule :title="title" :assetDimensions="[]" class="title" />
      <button class="icon filterButton" @click="showToolBox = !showToolBox" v-if="data.length > 0">
        <span v-if="!showToolBox"> {{ $t('actions.showSettings') }} <SettingsIcon size="1.5x" class="filter-icon" /> </span>
        <span v-else> {{ $t('actions.hideSettings') }} <XIcon size="1.5x" class="filter-icon" /> </span>
      </button>
    </header>
    <NoDataBlockStyled v-if="!$apollo.loading && data.length === 0">
      {{ messages }}
    </NoDataBlockStyled>
    <PivotTableAtom :data="data" :rows="rows" :cols="cols" :locale="locale" :vals="vals" v-else />
  </PivotTableWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { buttonReset, flexCenter } from '@styles/mixins'
import { XIcon, SettingsIcon } from 'vue-feather-icons'
import PivotTableAtom from '../Atoms/PivotTableAtom'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'

const PivotTableWrapper = styled('div')`
display: grid;
grid-template-rows: 3rem 100%;
grid-template-columns: auto;
position: relative;
height: calc(100% - 3rem);
 > header {
    position: relative;
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .title {
      padding: 0;
      > header {
        gap: 1rem;
      }
    }


  > button.icon,
  button.styleless {
    position: relative
    float: right;
    right: 1rem;
    ${buttonReset}
    ${flexCenter}
    padding: 0;
    color: ${({ theme }) => theme.colors.atomic.primary};
    width: max-content;
    height: 2rem;
    > span {
      gap: 0.2rem;
      display:flex;
      align-items:center;
    }
    > .filter-icon {
      background: ${({ theme }) => theme.colors.atomic.tableBG};
    }
  }
 }
  .pivotTable,
  .pvtUi td.pvtOutput {
    ${({ hideToolBox }) => hideToolBox && `padding:0; border: unset;`};
  }

  .pvtRenderers,
  .pvtAxisContainer,
  .pvtUnused,
  .pvtHorizList,
  .pvtVals,
  .pvtCols {
    ${({ hideToolBox }) => hideToolBox && `display:none;`};
  }
`
const NoDataBlockStyled = styled('div')`
  ${flexCenter}
  height: 100%;
  width: 100%;
`

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    cols: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    vals: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  components: {
    PivotTableWrapper,
    PivotTableAtom,
    XIcon,
    SettingsIcon,
    NoDataBlockStyled,
    WidgetHeaderMolecule,
  },
  data() {
    return {
      showToolBox: true,
    }
  },
  computed: {
    title() {
      return this.$t('widgetTypes.PIVOT_TABLE')
    },
    messages() {
      return this.$t('messages.noDimensionData', { dimension: this.$t('massflow') })
    },
  },
}
</script>
