<template>
  <AssetDimensionPivotTableWidget
    v-if="assetDimension && selectedTimeframeParam && assetsSelected"
    :selectedTimeframeParam="selectedTimeframeParam"
    :assets="assetsSelected"
    :selectedUIUnitSystem="selectedUIUnitSystem"
    :isMobile="isMobile"
    :elementSize="elementSize"
    :assetDimension="assetDimension"
    :widget="widget"
    :isPreview="isPreview"
    :allAssets="assetStore.allAssets"
  />
  <TalpaLoaderWrapper v-else />
</template>

<script>
import AssetDimensionPivotTableWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionPivotTableWidget'
import localesMixin from '@/mixins/locales'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import { get } from 'lodash'
import { TalpaLoaderWrapper } from '@common/components'
import { useAssetStore } from '@/stores/assets'

export default {
  inject: ['theme', 'uiSettings'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  props: {
    widget: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
    },
    pdfData: {
      type: Object,
      required: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [TimeframeMixin, localesMixin, resizeMixin],
  components: {
    AssetDimensionPivotTableWidget,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      isMobile: true,
      elementSize: 96,
    }
  },
  computed: {
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
    dimensions() {
      const dimensions = get(this.widget, 'dimensions', [])
      return dimensions
    },
    assetDimension() {
      return this.dimensions.map(e => e?.assetDimension?.name)
    },
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        this.elementSize = size
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
  },
}
</script>
