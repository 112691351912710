var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionPivotTableWidgetStyled', {
    attrs: {
      "elementSize": _vm.elementSize
    }
  }, [_vm.$apollo.loading || !_vm.assets.length ? _c('TalpaLoaderWrapper') : [_c('PivotTableMolecule', {
    attrs: {
      "data": _vm.mappedMassFlow,
      "rows": _vm.rowAttributes,
      "cols": _vm.columnAttributes,
      "locale": _vm.locale,
      "vals": _vm.defaultVals,
      "count": this.assetIds.length
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }