export default {
  cycle_duration_avg: 'avgDuration',
  cycles: 'noCycles',
  cycle_distance: 'avgDistance',
  energy_consumption_per_hour: 'energyConsumptionPerHour',
  energy_consumption_per_tonnage: 'energyConsumptionPerTonnage',
  liters_per_hour: 'fuelPerHour',
  liters_per_tonne: 'fuelPerTonnage',
  distance: 'sumDistance',
  cycle_duration_sum: 'sumDuration',
  tonnage: 'tonnage',
  tonne_kilometers: 'tonnageKilometer',
}
