var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PivotTableCanvasWrapper', [_c('VuePivottableUi', {
    staticClass: "pivotTable",
    attrs: {
      "data": _vm.data,
      "rows": _vm.rows,
      "cols": _vm.cols,
      "locales": _vm.locales,
      "locale": _vm.locale,
      "aggregatorName": 'First',
      "aggregators": _vm.aggregators,
      "vals": [_vm.vals]
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }