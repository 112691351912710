var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PivotTableWrapper', {
    attrs: {
      "hideToolBox": !_vm.showToolBox
    }
  }, [_c('header', [_c('WidgetHeaderMolecule', {
    staticClass: "title",
    attrs: {
      "title": _vm.title,
      "assetDimensions": []
    }
  }), _vm.data.length > 0 ? _c('button', {
    staticClass: "icon filterButton",
    on: {
      "click": function click($event) {
        _vm.showToolBox = !_vm.showToolBox;
      }
    }
  }, [!_vm.showToolBox ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('actions.showSettings')) + " "), _c('SettingsIcon', {
    staticClass: "filter-icon",
    attrs: {
      "size": "1.5x"
    }
  })], 1) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('actions.hideSettings')) + " "), _c('XIcon', {
    staticClass: "filter-icon",
    attrs: {
      "size": "1.5x"
    }
  })], 1)]) : _vm._e()], 1), !_vm.$apollo.loading && _vm.data.length === 0 ? _c('NoDataBlockStyled', [_vm._v(" " + _vm._s(_vm.messages) + " ")]) : _c('PivotTableAtom', {
    attrs: {
      "data": _vm.data,
      "rows": _vm.rows,
      "cols": _vm.cols,
      "locale": _vm.locale,
      "vals": _vm.vals
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }