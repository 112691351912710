<template>
  <PivotTableCanvasWrapper>
    <VuePivottableUi
      class="pivotTable"
      :data="data"
      :rows="rows"
      :cols="cols"
      :locales="locales"
      :locale="locale"
      :aggregatorName="'First'"
      :aggregators="aggregators"
      :vals="[vals]"
    />
  </PivotTableCanvasWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { VuePivottableUi, PivotUtilities } from 'vue-pivottable'
import { pivotTableStyles } from '@styles/misc'
import localesMixin from '@/mixins/locales'

const PivotTableCanvasWrapper = styled('div')`
  overflow: auto;

  ${pivotTableStyles}
  > .pivotTable {
    color: ${props => props.theme.colors.atomic.textMain};
    border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
    .pvtUi,
    .pvtDropdown {
      color: ${props => props.theme.colors.atomic.textMain};
      border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
    }
    .pvtRenderers,
    .pvtAxisContainer,
    .pvtVals {
      background: ${({ theme }) => theme.colors.atomic.tableBG};
      border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
    }

    .pvtAxisContainer li span.pvtAttr {
      background: ${({ theme }) => theme.colors.solidBG};
      border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
      > .pvtTriangle {
        color: ${props => props.theme.colors.atomic.primary};
      }
    }
    .pvtFilterBox {
      background: ${({ theme }) => theme.colors.solidBG};
      input[type='text'] {
        width: 95%;
        border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
        color: ${props => props.theme.colors.atomic.textMain};
      }
    }
    .pvtButton {
      background: ${({ theme }) => theme.colors.solidBG};
      color: ${props => props.theme.colors.atomic.primary};
      border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
      &:hover {
        background: ${({ theme }) => theme.colors.solidBG};
        color: ${props => props.theme.colors.atomic.primaryActive};
        border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
      }
    }
    .pvtSearch {
      background: ${({ theme }) => theme.colors.atomic.bunker};
    }
    .pvtCheckContainer p {
      background: ${({ theme }) => theme.colors.atomic.bunker};
      border-top: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
    }

    td.pvtOutput {
      background: ${({ theme }) => theme.colors.atomic.tableBG};
    }
    table.pvtTable thead,
    table.pvtTable tbody {
      tr {
        th {
          border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
          background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
        }
        td {
          color: ${props => props.theme.colors.atomic.textMain};
          border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.32).css()};
          background: ${({ theme }) => theme.colors.atomic.tableBG};
        }
      }
    }
  }
`

export default {
  mixins: [localesMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    cols: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    vals: {
      type: String,
      required: true,
    },
  },
  components: {
    PivotTableCanvasWrapper,
    VuePivottableUi,
  },
  data() {
    return {
      locales: {
        en: PivotUtilities.locales.en,
        de: {
          aggregators: PivotUtilities.aggregators,
          localeStrings: {
            renderError: 'Beim Rendern der PivotTable-Ergebnisse ist ein Fehler aufgetreten.',
            computeError: 'Beim Berechnen der PivotTable-Ergebnisse ist ein Fehler aufgetreten.',
            uiRenderError: 'Beim Rendern der PivotTable-Benutzeroberfläche ist ein Fehler aufgetreten.',
            selectAll: this.$t('actions.selectAll'),
            selectNone: this.$t('actions.deselectAll'),
            tooMany: '(zu viele um sie aufzuzählen)',
            filterResults: 'Werte filtern',
            totals: this.$tc('total', 1),
            vs: 'gegen',
            by: 'mal',
            apply: this.$t('actions.apply'),
            cancel: this.$t('actions.cancel'),
          },
        },
        ru: {
          aggregators: PivotUtilities.aggregators,
          localeStrings: {
            renderError: 'Произошла ошибка при отображении результатов сводной таблицы.',
            computeError: 'Произошла ошибка при вычислении результатов сводной таблицы.',
            uiRenderError: 'Произошла ошибка при отображении пользовательского интерфейса сводной таблицы.',
            selectAll: this.$t('actions.selectAll'),
            selectNone: this.$t('actions.deselectAll'),
            tooMany: '(слишком много, чтобы перечислить)',
            filterResults: 'значения фильтра',
            totals: this.$tc('total', 1),
            vs: 'против',
            by: 'раз',
            apply: this.$t('actions.apply'),
            cancel: this.$t('actions.cancel'),
          },
        },
      },
    }
  },
  computed: {
    aggregators() {
      const usFmt = PivotUtilities.numberFormat({
        digitsAfterDecimal: 0,
        decimalSep: this.decimalSeperator,
        thousandsSep: this.thousandsSeperator,
      })
      const usFmtInt = PivotUtilities.numberFormat({
        digitsAfterDecimal: 2,
        decimalSep: this.decimalSeperator,
        thousandsSep: this.thousandsSeperator,
      })
      const usFmtPct = PivotUtilities.numberFormat({
        digitsAfterDecimal: 0,
        scaler: 100,
        suffix: '%',
      })
      return (tpl => ({
        Count: tpl.count(usFmtInt),
        'Count Unique Values': tpl.countUnique(this.usFmtInt),
        'List Unique Values': tpl.listUnique(', '),
        Sum: tpl.sum(usFmt),
        'Integer Sum': tpl.sum(this.usFmtInt),
        Average: tpl.average(usFmt),
        Median: tpl.median(usFmt),
        'Sample Variance': tpl.var(1, usFmt),
        'Sample Standard Deviation': tpl.stdev(1, usFmt),
        Minimum: tpl.min(usFmt),
        Maximum: tpl.max(usFmt),
        First: tpl.first(usFmt),
        Last: tpl.last(usFmt),
        'Sum over Sum': tpl.sumOverSum(usFmt),
        'Sum as Fraction of Total': tpl.fractionOf(tpl.sum(), 'total', usFmtPct),
        'Sum as Fraction of Rows': tpl.fractionOf(tpl.sum(), 'row', usFmtPct),
        'Sum as Fraction of Columns': tpl.fractionOf(tpl.sum(), 'col', usFmtPct),
        'Count as Fraction of Total': tpl.fractionOf(tpl.count(), 'total', usFmtPct),
        'Count as Fraction of Rows': tpl.fractionOf(tpl.count(), 'row', usFmtPct),
        'Count as Fraction of Columns': tpl.fractionOf(tpl.count(), 'col', usFmtPct),
      }))(PivotUtilities.aggregatorTemplates)
    },
  },
}
</script>
