<template>
  <AssetDimensionPivotTableWidgetStyled :elementSize="elementSize">
    <TalpaLoaderWrapper v-if="$apollo.loading || !assets.length" />
    <template v-else>
      <PivotTableMolecule
        :data="mappedMassFlow"
        :rows="rowAttributes"
        :cols="columnAttributes"
        :locale="locale"
        :vals="defaultVals"
        :count="this.assetIds.length"
      />
    </template>
  </AssetDimensionPivotTableWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { get, isEmpty } from 'lodash'
import { TalpaLoaderWrapper } from '@common/components'
import units, { resolveUnitUI } from '@/utils/units'
import parameterize from '@/utils/parameterize'
import PivotTableMolecule from '../../Molecules/PivotTableWidget'
import ADDITIONAL_ATTRIBUTES from '@/components/Widgets/PivotTable/pivotTableAdditionalAttributes'
import CUSTOM_DIMENSIONS from '@/components/Widgets/PivotTable/pivotTableAssetDimensions'

import MASSFLOW_QUERY from '#/graphql/assetDimensions/massFlow.gql'

const AssetDimensionPivotTableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
`

export default {
  inject: ['uiSettings'],
  props: {
    assetDimension: {
      type: Array,
      required: true,
    },
    assets: {
      type: Array,
      required: true,
    },
    selectedTimeframeParam: {
      type: Object,
      required: true,
    },
    selectedUIUnitSystem: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    elementSize: {
      type: Number,
      required: false,
      default: 96,
    },
    widget: {
      type: Object,
      required: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    allAssets: {
      type: Array,
      required: true,
    },
  },
  components: {
    AssetDimensionPivotTableWidgetStyled,
    TalpaLoaderWrapper,
    PivotTableMolecule,
  },
  data() {
    return {
      additionalAttributes: ADDITIONAL_ATTRIBUTES,
      massFlow: [],
      isLoading: false,
    }
  },
  computed: {
    assetIds() {
      return this.assets.map(({ id }) => id)
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.slice(0, 2).toLowerCase()
    },
    thousandsSeperator() {
      return get(this.uiSettings, 'units', 'METRIC') === 'METRIC' ? '.' : ','
    },
    decimalSeperator() {
      return get(this.uiSettings, 'units', 'METRIC') === 'METRIC' ? ',' : '.'
    },
    selectedWidgetSettings() {
      const widgetSettings = { ...get(this.widget, 'widgetSettings', {}) }
      let selectedSettings
      if (isEmpty(widgetSettings)) {
        selectedSettings = this.additionalAttributes
      } else {
        delete widgetSettings['id']
        delete widgetSettings['__typename']
        selectedSettings = this.additionalAttributes.filter(({ id }) => widgetSettings[id] === true)
        /** Columns */
        if (widgetSettings?.userSelectedColumnDimensions?.length > 0) {
          widgetSettings.userSelectedColumnDimensions.map(column =>
            selectedSettings.push({ id: column.id, label: CUSTOM_DIMENSIONS[column.assetDimension.name] }),
          )
        }
        /** Rows */
        if (widgetSettings?.userSelectedRowDimensions?.length > 0) {
          widgetSettings.userSelectedRowDimensions.map(row =>
            selectedSettings.push({ id: row.id, label: CUSTOM_DIMENSIONS[row.assetDimension.name], isRow: true }),
          )
        }
        if (this.isPreview) {
          /** Columns */
          if (widgetSettings.addColumns && widgetSettings.addColumns.length > 0) {
            widgetSettings.addColumns.map(column =>
              selectedSettings.push({ id: column.id, label: CUSTOM_DIMENSIONS[column.assetDimension.name] }),
            )
          }
          if (widgetSettings.removeColumns && widgetSettings.removeColumns.length > 0) {
            selectedSettings = selectedSettings.filter(({ id }) => id !== widgetSettings.removeColumns.map(({ id }) => id))
          }
          /** Rows */
          if (widgetSettings.addRows && widgetSettings.addRows.length > 0) {
            widgetSettings.addRows.map(row =>
              selectedSettings.push({ id: row.id, label: CUSTOM_DIMENSIONS[row.assetDimension.name], isRow: true }),
            )
          }
          if (widgetSettings.removeRows && widgetSettings.removeRows.length > 0) {
            selectedSettings = selectedSettings.filter(({ id }) => id !== widgetSettings.removeRows.map(({ id }) => id))
          }
        }
      }
      return selectedSettings
    },
    columnAttributes() {
      return this.selectedWidgetSettings.filter(f => !f.isRow).map(({ label }) => this.$tc(`pivotTable.${label}`))
    },
    rowAttributes() {
      return this.selectedWidgetSettings.filter(f => f.isRow).map(({ label }) => this.$tc(`pivotTable.${label}`))
    },
    mappedMassFlow() {
      const massflow = this.massFlow.map(massflowData => {
        delete massflowData.__typename
        const sourceAssetName = massflowData.sourceAsset
          ? this.allAssets.find(asset => asset.id === massflowData.sourceAsset)?.name
          : this.$t(`pivotTable.nonTalpaAsset`)
        const targetAssetName = massflowData.targetAsset
          ? this.allAssets.find(asset => asset.id === massflowData.targetAsset)?.name
          : this.$t(`pivotTable.nonTalpaAsset`)
        return {
          [this.$t(`pivotTable.asset`)]: massflowData.asset
            ? this.allAssets.find(asset => asset.id === massflowData.asset)?.name
            : this.$t(`pivotTable.nonTalpaAsset`),
          [this.$t(`pivotTable.sourceGeofence`)]: massflowData.sourceGeofence,
          [this.$t(`pivotTable.targetGeofence`)]: massflowData.targetGeofence,
          [this.$t(`pivotTable.materialType`)]: massflowData.materialType === null ? '-' : parameterize(massflowData.materialType),
          [this.$t(`pivotTable.sourceAsset`)]: sourceAssetName,
          [this.$t(`pivotTable.targetAsset`)]: targetAssetName,

          ...(this.assetDimension.find(dimension => dimension === 'cycle_duration_avg') && {
            [this.$t(`pivotTable.avgDuration`)]: massflowData.avgDuration
              ? units(
                  massflowData.avgDuration,
                  's',
                  resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'cycle_distance') && {
            [this.$t(`pivotTable.avgDistance`)]: massflowData.avgDistance
              ? units(
                  massflowData.avgDistance,
                  'm',
                  resolveUnitUI(this.selectedUIUnitSystem, 'length'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'energy_consumption_per_hour') && {
            [this.$t(`pivotTable.energyConsumptionPerHour`)]: massflowData.energyConsumptionPerHour
              ? units(
                  massflowData.energyConsumptionPerHour,
                  'Ws',
                  'kWh/h',
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'energy_consumption_per_tonnage') && {
            [this.$t(`pivotTable.energyConsumptionPerTonnage`)]: massflowData.energyConsumptionPerTonnage
              ? units(
                  massflowData.energyConsumptionPerTonnage,
                  'Ws/kg',
                  resolveUnitUI(this.selectedUIUnitSystem, 'energyMass'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'liters_per_hour') && {
            [this.$t(`pivotTable.fuelPerHour`)]: massflowData.fuelPerHour
              ? units(
                  massflowData.fuelPerHour,
                  'l/s',
                  resolveUnitUI(this.selectedUIUnitSystem, 'volumeFlowRates'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'liters_per_tonne') && {
            [this.$t(`pivotTable.fuelPerTonnage`)]: massflowData.fuelPerTonnage
              ? units(
                  massflowData.fuelPerTonnage,
                  'l/kg',
                  resolveUnitUI(this.selectedUIUnitSystem, 'volumeMass'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'cycles') && {
            [this.$t(`pivotTable.noCycles`)]: massflowData.noCycles ?? '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'distance') && {
            [this.$t(`pivotTable.sumDistance`)]: massflowData.sumDistance
              ? units(
                  massflowData.sumDistance,
                  'm',
                  resolveUnitUI(this.selectedUIUnitSystem, 'length'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'cycle_duration_sum') && {
            [this.$t(`pivotTable.sumDuration`)]: massflowData.sumDuration
              ? units(
                  massflowData.sumDuration,
                  's',
                  resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'tonnage') && {
            [this.$t(`pivotTable.tonnage`)]: massflowData.tonnage
              ? units(
                  massflowData.tonnage,
                  'kg',
                  resolveUnitUI(this.selectedUIUnitSystem, 'mass'),
                  0,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
          ...(this.assetDimension.find(dimension => dimension === 'tonne_kilometers') && {
            [this.$t(`pivotTable.tonnageKilometer`)]: massflowData.tonnageKilometer
              ? units(
                  massflowData.tonnageKilometer,
                  's',
                  resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          }),
        }
      })
      return massflow
    },
    defaultVals() {
      let dimension = CUSTOM_DIMENSIONS[this.assetDimension[0]]
      dimension = this.$t(`pivotTable.${dimension}`)
      return dimension
    },
  },
  apollo: {
    massFlow: {
      query: MASSFLOW_QUERY,
      variables() {
        return {
          shifts: this.selectedTimeframeParam.shifts,
          from_ts: this.selectedTimeframeParam.start,
          till_ts: this.selectedTimeframeParam.end,
          assetIds: this.assetIds,
        }
      },
      skip() {
        return this.assetIds.length < 1 || !this.selectedTimeframeParam
      },
    },
  },
}
</script>
